import { createApp, h } from "vue"
import { Link, createInertiaApp } from "@inertiajs/vue3"
import Alpine from "alpinejs"
import mask from "@alpinejs/mask"
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers"
import { ZiggyVue } from "ziggy-js"
import * as Sentry from "@sentry/vue"
import "vue3-easy-data-table/dist/style.css"
import "./bootstrap"
import "../css/app.css"

const appName = window.document.getElementsByTagName("title")[0]?.innerText || "Laravel"

window.Alpine = Alpine
Alpine.plugin(mask)
Alpine.start()

function getUserSegment(props) {
    if (!props.auth) {
        return "guest"
    }

    return props.appPermissions.seeEmployeeResources ? "employee" : "client"
}

/**
 * Focus search input when / is pressed.
 *
 * @link https://justincypret.com/blog/adding-a-keyboard-shortcut-for-global-search
 */
document.addEventListener("keyup", (e) => {
    if (e.key !== "/" || e.ctrlKey || e.metaKey) {
        return
    }

    var isInsideContentEditableElement = e.target.attributes.getNamedItem("contenteditable")

    if (/^(?:input|textarea|select|button)$/i.test(e.target.tagName) || isInsideContentEditableElement) {
        return
    }

    e.preventDefault()
    document.getElementById("global-search")?.focus()
})

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob("./Pages/**/*.vue")),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })

        if (import.meta.env.VITE_SENTRY_DSN_PUBLIC?.length > 1) {
            Sentry.init({
                app,
                dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
                environment: import.meta.env.VITE_APP_ENV,

                // Session Replay
                replaysSessionSampleRate: import.meta.env.VITE_SENTRY_REPLAYS_SAMPLE_RATE ?? 0.1,
                replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_REPLAYS_ERROR_SAMPLE_RATE ?? 1.0,

                // Performance Monitoring
                tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,

                trackComponents: true,

                integrations: [
                    Sentry.browserTracingIntegration({
                        tracePropagationTargets: ["localhost", import.meta.env.VITE_APP_URL],
                        // routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                    }),
                    Sentry.replayIntegration(),
                    Sentry.feedbackIntegration({
                        triggerLabel: "Problems with this page?",
                        formTitle: "Report a problem with LuminFire’s BrilliantPortal",
                    }),
                ],

                beforeSend(event, hint) {
                    // Check if it is an exception, and if so, show the report dialog
                    if (event.exception && event.event_id) {
                        Sentry.showReportDialog({
                            eventId: event.event_id,
                            user: {
                                name: props.initialPage.props.auth?.user?.name,
                                email: props.initialPage.props.auth?.user?.email,
                            },
                            labelComments: "Can you provide a bit more detail about what you were trying to do?",
                        })
                    }

                    return event
                },
            })

            if (import.meta.env.VITE_SENTRY_SEND_DEFAULT_PII === "true") {
                Sentry.setUser({
                    id: props.initialPage.props.auth?.user?.id,
                    username: props.initialPage.props.auth?.user?.name,
                    email: props.initialPage.props.auth?.user?.email,
                    segment: getUserSegment(props.initialPage.props),
                })
            }
        }

        return app.use(plugin).use(ZiggyVue).component("Link", Link).mount(el)
    },
    progress: {
        color: "#33A5DF",
    },
})
